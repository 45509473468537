import React, { useEffect } from 'react'
import { Flex, Box } from 'components/Layout'

const Calendly = (props) => {
  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    head.appendChild(script)
  }, [])

  return (
    <Flex alignItems="center" flexDirection="column" textAlign="center">
      <Box
        className="calendly-inline-widget"
        data-url={props.link}
        style={{ width: '100%', padding: '0px', overflow: 'hidden' }}
        height={{ mobile: '1350px', desktopWide: '1350px' }}
      />
    </Flex>
  )
}

export default Calendly
